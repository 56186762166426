@import "../../../assets/scss/theme";

.sortable-list {
    background-color: $white-ter;
    border: 1px solid $grey-lighter;
    border-radius: 3px;

    &__item {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: $white;
        border-bottom: 1px solid $grey-lighter;
        box-sizing: border-box;
        user-select: none;

        &:last-child {
            border-bottom: none;
        }
    }

    &__handle {
        cursor: ns-resize;
        padding: 10px;
        align-self: stretch;
        display: flex;
        align-items: center;
    }

    &__item-content {
        flex: 1;
    }

    &__list {

    }
}