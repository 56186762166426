@import "../../assets/scss/theme";

.toggle-wrapper {
    display: inline-flex;
    align-items: center;

    &__text {
        transform: translateY(-1px);
        margin-left: 5px;
        font-size: 14px;
    }
}

@mixin toggler-generator($mode: 'regular', $width: 12, $height: 12) {
    .toggle-#{$mode} {
        //-webkit-appearance: none;
        display: inline-block;
        background-color: darken($white, 2%);
        border: none;
        border-radius: $height;
        box-shadow: inset 0 0 0 1px $grey-lighter;
        cursor: pointer;
        height: $height;
        position: relative;
        transition: border 0.25s 0.15s, box-shadow 0.25s 0.3s, padding 0.25s;
        width: $width;
        vertical-align: top;

        &:after {
            background-color: $white;
            border: 1px solid $grey-lighter;
            border-radius: $height - 2;
            box-shadow: inset 0 -3px 3px hsla(0, 0%, 0%, 0.025), 0 1px 4px hsla(0, 0%, 0%, 0.15), 0 4px 4px hsla(0, 0%, 0%, 0.1);
            content: '';
            display: block;
            height: $height - 1;
            left: 0;
            position: absolute;
            right: $width - $height;
            top: 0;
            transition: border 0.25s 0.15s, left 0.25s 0.1s, right 0.15s 0.175s;
        }

        &--checked {
            border-color: $success;
            box-shadow: inset 0 0 0 ($height / 2) $success;
            padding-left: $width - $height + 2;
            transition: border 0.25s, box-shadow 0.25s, padding 0.25s 0.15s;

            &:after {
                border-color: $success;
                left: $width - $height;
                right: 0;
                transition: border 0.25s, left 0.15s 0.25s, right 0.25s 0.175s;
            }
        }

        &--indeterminate {
            background-color: $grey-lighter !important;
            border-color: $grey-lighter !important;
            box-shadow: inset 0 0 0 ($height / 2) $grey-lighter !important;

            &:after {
                border-color: $grey-lighter !important;
                left: ($width - $height) / 2 !important;
                right: ($width - $height) / 2 !important;
                transition: border 0.25s, left 0.15s 0.25s, right 0.25s 0.175s;
            }
        }

        &--info {
            &:checked {
                border-color: $info;
                box-shadow: inset 0 0 0 ($height / 2) $info;

                &:after {
                    border-color: $info;
                }
            }
        }

        &--success {
            &:checked {
                border-color: $success;
                box-shadow: inset 0 0 0 ($height / 2) $success;

                &:after {
                    border-color: $success;
                }
            }
        }

        &--primary {
            &:checked {
                border-color: $primary;
                box-shadow: inset 0 0 0 ($height / 2) $primary;

                &:after {
                    border-color: $primary;
                }
            }
        }

        &--warning {
            &:checked {
                border-color: $warning;
                box-shadow: inset 0 0 0 ($height / 2) $warning;

                &:after {
                    border-color: $warning;
                }
            }
        }

        &--danger {
            &:checked {
                border-color: $danger;
                box-shadow: inset 0 0 0 ($height / 2) $danger;

                &:after {
                    border-color: $danger;
                }
            }
        }
    }
}

@include toggler-generator('regular', 46px, 22px);
@include toggler-generator('big', 200px, 50px);