@import "~bulma/bulma";
@import "_variables";
@import "_theme";
@import "_overrides";
@import "_spacing";
@import "_bems";

//* ============ VENDOR =========== */
:root {
  .card {
    --bulma-card-radius: 0;
  }

  --bulma-primary-invert: white;
  --bulma-primary-rgb: 0, 209, 178;
  --bulma-primary-h: 13deg;
  --bulma-primary-s: 35%;
  --bulma-primary-l: 84%;
  --bulma-primary: #{$primary};


  --bulma-link-text: #{$link};

  --bulma-link-text-active: #{$link-active};

  --bulma-info: #{$info};
  --bulma-info-h: 199;
  --bulma-info-s: 56%;
  --bulma-info-l: 81%;

   $pagination-current-color:  #{$info};
  --bulma-pagination-current-background-color: #{$info};
  --bulma-pagination-current-background-color-h: 0deg;
  --bulma-pagination-current-background-color-s: 0%;
  --bulma-pagination-current-background-color-l: 96%;

    $info: #{info};
  --bulma-cyan: #{$cyan};
  --bulma-purple: #{$purple};
  --bulma-yellow: #{$yellow};

  --bulma-warning: #{$warning};
  --bulma-warning-h: 40;
  --bulma-warning-s: 76%;
  --bulma-warning-l: 82%;

  --swiper-theme-color: #E5CFC9;
  --swiper-navigation-color: rgba(255, 255, 255, 0.81);
}


@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/solid";
//* ============ END VENDOR =========== */

.field:not(:last-child) {
  margin-bottom: .75rem;
}