
/* ============ FORMS =========== */
legend {
    display: block !important;
    padding: 0 !important;
    text-transform: uppercase;
    border-bottom: 1px solid $grey-light;
    color: $grey;
    margin-bottom: $spacer / 3 !important;
}

fieldset {
    border: 0;
    margin: 0 0 30px !important;
    min-width: 0;
    padding: 0.01em 0 0 0;
}

body:not(:-moz-handler-blocked) fieldset {
    display: block;
}
/* ============ END FORMS =========== */

/* ============ BULMA: FORMS =========== */
.field > label {
    font-weight: 200;
}
/* ============ END BULMA: FORMS =========== */

/* ============ ZIPPY =========== */
.zippy-react-toolkit-notification.zippy-react-toolkit-notification--theme-default {
    z-index: 30;
}
/* ============ END ZIPPY =========== */

/* ============ COMMON =========== */
hr {
    margin: 0.5rem 0 !important;
}
/* ============ END COMMON =========== */

/* ============ BULMA TABLE =========== */
.table {
    &--align-middle {
        td {
            vertical-align: middle !important;
        }
    }

    &--mini-table {
        th,
        td {
            font-size: 12px;
        }
    }
}

.table:not(.is-narrow) {
    td {
        padding: 0 !important;
        vertical-align: middle !important;
    }

    th {
        vertical-align: middle !important;
    }

    tfoot {
        td {
            padding: 0.5em 0.75em !important;
        }
    }
}
/* ============ END BULMA TABLE =========== */

/* ============ CARD =========== */
.card-header {
    &--form-caption {
        justify-content: center;
        text-align: center;
        padding: 15px 10px;
        background-color: $grey-light !important;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 16px;
    }

    &--is-column {
        flex-direction: column;
    }
}
/* ============ END CARD =========== */

/* ============ NOTIFICATION =========== */
.zippy-react-toolkit-notification {
    $zrtn: &;
    border-radius: 4px;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
    z-index: 1001 !important;

    &__header {
        padding-left: 12px !important;
    }

    &.success {
        color: $light !important;
        background-color: $success !important;
    }

    &.warning {
        color: $dark !important;
        background-color: $warning !important;

        #{$zrtn}__pin-button,
        #{$zrtn}__close-button {
            fill: $dark !important;
        }
    }

    &.danger {
        color: $light !important;
        background-color: $danger !important;
    }

    &.info {
        color: $light !important;
        background-color: $info !important;
    }
}
/* ============ END NOTIFICATION =========== */