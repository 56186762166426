.image-manager {
    width: 100%;

    &__item {
        display: flex;
        align-items: center;
    }

    &__image {
        cursor: zoom-in;
        user-select: none;

        img {
            object-fit: cover;
            object-position: top;
            display: block;
            width: auto;
            height: 100px;
        }
    }

    &__actions {
        flex: 1;
        text-align: right;
        padding-right: 20px;
    }
}