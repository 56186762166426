@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700');

// Colors

$black:        hsl(0, 0%, 4%) !default;
$black-bis:    hsl(0, 0%, 7%) !default;
$black-ter:    hsl(0, 0%, 14%) !default;

$grey-darker:  hsl(0, 0%, 21%) !default;
$grey-dark:    hsl(0, 0%, 29%) !default;
$grey:         hsl(0, 0%, 48%) !default;
$grey-light:   hsl(0, 0%, 71%) !default;
$grey-lighter: hsl(0, 0%, 86%) !default;

$white-ter:    hsl(0, 0%, 96%) !default;
$white-bis:    hsl(0, 0%, 98%) !default;
$white:        hsl(0, 0%, 100%) !default;

$orange:       hsl(14,  100%, 53%) !default;
$yellow: hsl(40, 76%, 82%) !default;
$green:        hsl(154, 47%, 66%) !default;
$turquoise:   hsl(199, 56%, 81%) !default;
$cyan:        	hsl(93, 24%, 80%) !default;
$blue: hsl(216, 80%, 75%) !default;
$purple:       	hsl(256, 14%, 59%) !default;
$red:         hsl(360, 100%, 75%) !default;

// Typography
$family-sans-serif: 'Open Sans', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-monospace: monospace !default;
$render-mode: optimizeLegibility !default;

$size-1: 3rem !default;
$size-2: 2.5rem !default;
$size-3: 2rem !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 1rem !default;
$size-7: 0.75rem !default;

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;

// Responsiveness
// The container horizontal gap, which acts as the offset for breakpoints
$gap: 64px !default;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default;
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default;
$widescreen-enabled: true !default;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default;
$fullhd-enabled: true !default;

// Miscellaneous
$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 4px !default;
$radius-large: 6px !default;
$radius-rounded: 290486px !default;
$speed: 86ms !default;
$section-padding: 1rem 1.5rem !default;

// Flags
$variable-columns: true !default;

// Update some of Bulma's component variables
$control-border-width: 1px;
$input-shadow: none;

// Derived
$primary: hsl(13deg, 35%, 84%) !default;


// palette
$primary-10: #E5CFC9;
$primary-20: #C8AFB2;
$primary-30: #A7929E;
$primary-40: #81778A;
$primary-50: #585F73;

$primary-accent-alt: #d8deed;
$primary-accent-10: #2F4858;
$primary-accent: #1D667E;

$link: $blue !default;
$link-active: $blue !default;

$info: 	hsl(223, 37%, 89%) !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;

$light: $white-ter !default;
$dark: $grey-darker !default;