@import "../../assets/scss/variables";
@import "../../assets/scss/theme";

.content-section {
    position: relative;
    margin-bottom: $spacer;

    &__header {
        margin-bottom: $spacer / 3;
    }

    &__title {
        color: $grey-dark;
        font-size: 20px;
        font-weight: 600;
    }
}