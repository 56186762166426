@import "../../assets/scss/theme";

.project-profile {
    &__actions {

    }

    &__is-favorite {
        .icon {
            color: $red;
        }
    }

    &__is-selected {
        .icon {
            color: $success;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 350px;
        object-fit: cover;
        object-position: top;
    }
}