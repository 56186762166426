@import "../../assets/scss/variables";
@import "../../assets/scss/theme";

.aside-block {
    margin-top: $spacer / 2;
    margin-bottom: $spacer;

    &:last-child {
        margin-bottom: 0;
    }

    &__header {
        margin-bottom: $spacer / 4;
    }

    &__title {
        text-transform: uppercase;
        font-size: 14px;
        color: $grey;
    }
}