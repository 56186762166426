.pairs {
    &__pair {
        margin-bottom: $spacer / 3 !important;
    }

    &__key {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        color: $grey-light;
    }

    &__value {
        font-size: 16px;
    }
}

.responsive-table-wrapper {
    overflow-x: auto;
}