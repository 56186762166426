.modal-card-body {
    min-height: 200px;
    overflow: inherit;
    margin-bottom: 2em;
}

.modal-card-head {
    padding-bottom: 0.5em;
    margin-bottom: 1.8em;
    border-bottom: 1px solid #989898;
    box-shadow: inherit;
}

.modal-card {
    padding:0.3em;
}

.modal-card-foot > * {
    margin-right: 0.7em;
}