.table-cell {
    padding: 0 !important;

    &__link {
        padding: 0.5em 0.75em;
        display: block;

        &--undecorated {
            color: #363636;
            text-decoration: none;
        }
    }
}