.drop-zone {
    $dz: &;
    $dark-color: darken(gainsboro, 20);
    background-color: gainsboro;
    outline: 2px dashed $dark-color;
    outline-offset: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    transition: all 0.15s ease;
    margin-bottom: 30px;

    &--is-active {
        outline-offset: -15px;
        background-color: $dark-color;
        outline: 2px dashed gainsboro;

        #{$dz}__label {
            color: gainsboro;
        }
    }

    &--reject {
        background-color: crimson;
    }

    &__label {
        color: $dark-color;
        text-align: center;

        & > .svg-inline--fa {
            font-size: 36px;
            margin-bottom: 5px;
        }
    }
}