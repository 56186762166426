.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    &__elements {
    }

    &__totals {
        font-size: 12px;
    }
}