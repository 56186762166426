@import "../../assets/scss/theme";

.profile-card {
    width: 100%;
    position: relative;

    &--checked {
        border: 3px solid #0075ff;
        border-radius: 4px;
    }

    .checkbox {
        z-index: 2;
        right: 3%;
        top: 3%;
        position: absolute;
        input {
            transform: scale(2);
        }
    }

    .select {
        width: 100%;
    }

    .card-footer-item {
        flex-basis: auto;
        color: $primary-accent;
        padding: 10px;
        font-size: 14px;

        &.button {
            background: none;
            border-top: none;
            border-left: none;
            border-bottom: none;
            border-radius: 0;
            box-shadow: none;
            padding: 0;
            height: auto !important;

            &:hover {
                box-shadow: none;
            }

            &:focus {
                box-shadow: none;
            }
        }

        &:hover {
            background-color: $grey-lighter;
        }
    }

    .card-content {
        padding: 15px;
        min-height: 220px;
    }

    &__image {
        display: block;
        width: 100%;
        height: 280px;
        object-fit: cover;
        object-position: top;
    }
}

.profile-age {
    text-align: center;
    border: 1px solid $grey-lighter;
    min-width: 40px;

    &__number {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0;
        padding: 2px;
    }

    &__units {
        text-transform: uppercase;
        font-size: 10px;
        background-color: $grey-lighter;
    }
}