@import "../../assets/scss/variables";

.lightbox-wrapper {
    margin-top: $spacer / 3;
    margin-bottom: $spacer / 3;

    &__swiper {
        .swiper-slide {
            &:hover {
                cursor: zoom-in;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 80px;
        object-fit: cover;
        object-position: top;
    }
}